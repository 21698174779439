jQuery(document).ready(function($){

    // show hide notifi popup

    $(window).resize(function() {
        separatorHandler();
    }).trigger('resize');
    function separatorHandler() {
        let widthElement = $('#project-list').outerWidth();
        console.log(widthElement);
        $('.bullet').each(function() {
            $(this).show();
            if($(this).position().left < 40 || $(this).position().left > widthElement - 40) {
                console.log($(this).position().left);
                $(this).hide();

            }
        });
    }
    $('#open').on('click', function(){
        let _this = $(this)
        $('.project-list').addClass('open');
        $('.up-button').addClass('open');
        setTimeout(function () {
            _this.hide();
        },1000)
    });
    var $root = $('html, body');

    $('a[href^="#"]').click(function() {
        var href = $.attr(this, 'href');

        $root.animate({
            scrollTop: $(href).offset().top
        }, 500, function () {
            window.location.hash = href;
        });

        return false;
    });
    separatorHandler();
    // $(window).scroll(function () {
    //     let pageOffset = window.pageYOffset;
    //     let projectOffset = $('.section-project').position().top;
    //     if(pageOffset > projectOffset){
    //         $('.up-button').show()
    //     } else {
    //         $('.up-button').hide()
    //     }
    // });
});



$(window).on("load",function(){
    //custom scroll line in window

});


